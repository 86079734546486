import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const TeamAreaTwo = () => {
  return (
    <>
      {/*====================== team area start ======================*/}
      <div className="about-area bg-gradient-gray pd-top-120">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">OUR STAFF</h6>
            <h3 className="title">
            Palliative Care Unit <span>Staff</span> Members
                </h3>
          </div>
          <div className="row">

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner style-2 text-center">
                <div className="thumb">
                  <img src="assets/img/team/a.png" alt="img" />
                </div>
                <div className="details">
                  <div className="details-inner">
                    <h5>
                      <Link to="/team-details">Mr. Anura Rathnayake</Link>
                    </h5>
                    <p>Nurse in Charge <br/>
                    Palliative Care Unit</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner style-2 text-center">
                <div className="thumb">
                  <img src="assets/img/team/v.png" alt="img" />
                </div>
                <div className="details">
                  <div className="details-inner">
                    <h5>
                      <Link to="/team-details">Ms. L.A Vindhya Nimali Alahakoon</Link>
                    </h5>
                    <p>Palliative Care Nursing Officer <br/>
                    Palliative Care Unit</p>
                  </div>
                </div>
              </div>
            </div>
            

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner style-2 text-center">
                <div className="thumb">
                  <img src="assets/img/team/s.png" alt="img" />
                </div>
                <div className="details">
                  <div className="details-inner">
                    <h5>
                      <Link to="/team-details">Ms. D.B.A.G.S.C. Wijewardana</Link>
                    </h5>
                    <p>Palliative Care Nursing Officer <br/>
                    Palliative Care Unit</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner style-2 text-center">
                <div className="thumb">
                  <img src="assets/img/team/k.png" alt="img" />
                </div>
                <div className="details">
                  <div className="details-inner">
                    <h5>
                      <Link to="/team-details">Ms. K.K.S. Gunawardhana</Link>
                    </h5>
                    <p>Palliative Care Nursing Officer <br/>
                    Palliative Care Unit</p>
                  </div>
                </div>
              </div>
            </div>

            

          </div>
        </div>
      </div>

      {/* ====================== team area end ======================*/}
    </>
  );
};

export default TeamAreaTwo;
