import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ContactMain from "../components/ContactMain";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import NavbarThree from "../components/NavbarThree";
import FooterTwo from "../components/FooterTwo";
import ContactAreaFour from "../components/ContactAreaFour";

const Contact = () => {
  return (
    <>
      {/* Navigation Bar 
      <NavBar />
      */}
      <NavbarThree />

      {/* Navigation Bar */}
      <Breadcrumb title={"Contact"} />

      {/* Contact Main */}
      
      <ContactMain />

      <ContactAreaFour />

      {/* Footer One */}
      <FooterTwo />
    </>
  );
};

export default Contact;
