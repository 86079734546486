import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 p-5 ">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="animate-img-3 top_image_bounce"
                  src="assets/img/banner/5.svg"
                  alt="img"
                />
                <img
                  className="main-img"
                  src="assets/img/banner/4.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                Society for <span>Palliative Care</span>
                </h2>
                <p className="content mb-4 mb-xl-5">
                Our palliative care unit of National Hospital Kandy is dedicated to providing compassionate care for patients and families facing life-threatening illnesses. We focus on enhancing the quality of life for patients and their families by addressing physical, emotional, and spiritual needs.
                </p>

              </div>
            </div>
          </div>


        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
