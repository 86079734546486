import React from "react";
import { FaHeart } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaFour = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* =============== About Area Three End ===============*/}
      <div className="about-area pd-top-90 pd-bottom-110">
        <div className="container">

        <div className="row">
            <div className="col-md-6 p-5 ">
              <div className="single-about-inner text-center align-items-center">
                <div className="thumb mb-3 mx-auto">
                  <img src="assets/img/icon/2.png" alt="img" />
                </div>
                <div className="details text-center ">
                  <h5>Our Vision</h5>
                  <p>
                  To provide compassionate, holistic, and dignified care for individuals with life-limiting illnesses, ensuring their physical, emotional, social, and spiritual well-being, while supporting their families in Kandy and surrounding regions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-5 ">
              <div className="single-about-inner text-center align-items-center">
                <div className="thumb mb-3 mx-auto">
                  <img src="assets/img/icon/3.png" alt="img" />
                </div>
                <div className="details text-center ">
                  <h5>Our mission</h5>
                  <p>
                  To deliver high-quality palliative care through: <br/>

Comprehensive symptom management for patients with serious illness. <br/>

Emotional, psychological, and spiritual support for patients and their families. <br/>

Education and training for healthcare professionals in palliative care practices. <br/>

Collaboration with community resources to enhance access to end-of-life care.
                  </p>
                </div>
              </div>
            </div>
          </div>



          
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner p-xl-5 pt-4 "
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <img
                  className="animate-main-img"
                  src="assets/img/about/14.png"
                  alt="img"
                />
                <img
                  className="main-img m-md-4"
                  src="assets/img/about/about_bnr.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center "
              data-aos="fade-left"
              data-aos-delay="250"
              data-aos-duration="1500"
            >

              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title"> SOCIETY FOR PALLIATIVE CARE</h6>
                <h3 className="title">
                  Why Support <span>Palliative Care Unit</span> ?
                </h3>
<br/>
                <p>
                Support for a palliative care unit is essential for several reasons, as these units play a critical role in improving the quality of life for patients facing life-limiting illnesses. 
<br/> <br/>
In essence, supporting a palliative care unit enhances the quality of life for patients, provides relief to families, and ensures that compassionate care is available when it is most needed.                </p>
                

                <Link
                  className="mt-3 btn btn-border-base"
                  data-aos="fade-right"
                  data-aos-delay="400"
                  data-aos-duration="1500"
                  to="/donation"
                  onClick={scrollToTop}
                >
                  Make a Donation <FaHeart />
                </Link>

                {/*
                <div className='media box-shadow p-3 border-radius-5 mb-3'>
                  <div className='media-left me-3'>
                    <img src='assets/img/icon/31.svg' alt='img' />
                  </div>
                  <div className='media-body'>

                  <Link
                      className='mt-3 btn btn-border-base'
                      data-aos='fade-right'
                      data-aos-delay='400'
                      data-aos-duration='1500'
                      to='/about'
                    >
                      Make a Donation  <FaHeart />
                    </Link>

                    <div className='single-progressbar'>
                      
                    

                    </div>
                  </div>
                </div>
                <div className='media box-shadow p-3 border-radius-5 mb-3'>
                  <div className='media-left me-3'>
                    <img src='assets/img/icon/32.svg' alt='img' />
                  </div>
                  <div className='media-body'>
                    <div className='single-progressbar'>
                      <h6>Cloud Solutions</h6>
                      <div className='skill'>
                        <div
                          style={{ width: "80%" }}
                          className='skill-bar skill1 wow slideInLeft animated'
                        ></div>
                        <p className='skill-count1'>
                          <span className='counter'>80</span> %
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
*/}
              </div>


            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </>
  );
};

export default AboutAreaFour;
