let serviceList = [
  {
    title: "Symptom Management.",
    des: "Relief from symptoms like pain, shortness of breath, fatigue, nausea, and anxiety. Tailored treatments based on the patient's condition and needs.",
    img: "assets/img/icon/s1.svg",
  },
  {
    title: "Emotional and Psychological Support.",
    des: "Counseling for patients and their families to cope with emotional challenges such as depression, fear, and stress. Grief and bereavement support for family members.",
    img: "assets/img/icon/s2.svg",
  },
  {
    title: "Spiritual Support.",
    des: "Providing chaplaincy services or access to spiritual advisors for patients seeking meaning, faith, or religious comfort. Respecting all cultural and religious practices.",
    img: "assets/img/icon/s3.svg",
  },
  {
    title: "Family and Caregiver Support.",
    des: "Offering training programs and guidance to family members on how to care for the patient. Respite care to provide temporary relief to caregivers. Facilitating virtual and physical family meetings to discuss goals of care, treatment options, and decisions.",
    img: "assets/img/icon/s4.svg",
  },

  {
    title: "End-of-Life Care.",
    des: "Providing comfort care in the final stages of illness. Support during the dying process, ensuring a dignified and peaceful end of life.",
    img: "assets/img/icon/s5.svg",
  },
  {
    title: "Education and Counseling.",
    des: "Informing patients and families about the nature of the illness and the available care options. Offering resources to manage caregiving at home or making transitions to hospice care when necessary. Palliative care can be offered at any stage of illness, alongside curative treatments or on its own, depending on the patient’s needs.",
    img: "assets/img/icon/s6.svg",
  },
  {
    title: "Healthcare Professionals' Training.",
    des: "Training Programs: Continuous education and specialized training for healthcare professionals in areas like symptom management, effective communication, and providing emotional and spiritual support. Workshops & Seminars: Training staff to handle bereavement support, family counseling, and understanding complex family dynamics. Interdisciplinary Collaboration: Training includes collaboration among physicians, nurses, social workers, and spiritual care providers to deliver comprehensive palliative care.",
    img: "assets/img/icon/s7.svg",
  },
  {
    title: "Bereavement Care.",
    des: "Grief Counseling: Ongoing support for families before and after the loss of a loved one, offering grief counseling individually or in group sessions. Memorial Services: Organizing memorial events or remembrance days for families to commemorate loved ones. Follow-up: Palliative teams stay connected with families for several months post-loss, offering resources and emotional support through letters, calls, or meetings.",
    img: "assets/img/icon/s8.svg",
  },
  {
    title: "Helping Patients’ Children with Education.",
    des: "Educational Support: Assistance programs to ensure the children of terminally ill patients continue their education smoothly. Scholarship Programs: Connecting families to scholarship programs or financial aid for education, especially for children who might lose a parent. Counseling for Children: Providing counseling to help children cope with a parent's illness and maintain focus on their education.",
    img: "assets/img/icon/s9.svg",
  },
  {
    title: "Guidance for Self-Employment.",
    des: "Vocational Counseling: Offering career counseling and skills training for family members or patients who are able to work, to help them explore self-employment or flexible job opportunities. Partnerships with volunteers support  or Government: Linking families with local organizations that offer micro-loans, vocational training, or entrepreneurial guidance. Mentorship Programs: Arranging mentorship for family members who want to pursue small business ventures or learn a trade.",
    img: "assets/img/icon/s10.svg",
  },
  {
    title: "Physical and Virtual Family Meetings.",
    des: "In-person Family Conferences: Regular meetings with healthcare teams to discuss the patient's condition, goals of care, and treatment options. These meetings are crucial for ensuring that all family members are on the same page. Virtual Meetings: Offering online family meetings via video conferencing for family members who are unable to visit in person, ensuring participation of all important stakeholders. Decision-Making Support: The meetings help families make informed decisions about treatment, end-of-life care, or hospice referrals.",
    img: "assets/img/icon/s11.svg",
  },
  {
    title: "Over-the-Phone Connection.",
    des: "A dedicated phone line where families can reach healthcare professionals for updates, advice, or support during emergencies. Counseling Over the Phone: health professionals available for phone consultations, offering guidance to family members or patients who need immediate support. Regular Check-ins: Nurses conduct routine check-ins over the phone, especially for patients receiving care at home. This comprehensive model ensures that the palliative care team addresses the complex needs of both patients and families—medical, emotional, educational, and practical—through multiple channels of communication and support",
    img: "assets/img/icon/s12.svg",
  },
];

export default serviceList;
