let donationList = [
  {
    title: "Bank of Ceylon , Kandy",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    acc: "92973717",
    bank: "7010",
    branch: "454",
    branch1: "Kandy Hospital Branch",
    ac: "Society for palliative care",
    swift: "BCEYLKLX",
    img: "assets/img/banks/Boc.png",
  },
 
];

export default donationList;
