import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const TeamAreaOne = () => {
  return (
    <>
      {/*====================== team area start ======================*/}
      <div className="team-area bg-relative pd-top-90 pd-bottom-90">
        <div className="container">

          
          <div className="section-title text-center">
            <h6 className="sub-title">
              COMMITTEE MEMBERS OF PALLIATIVE CARE SOCIETY
            </h6>

            {/*
            <h3 className="title">
              Venerable <span>Patrons</span>
            </h3>
            */}

          </div>

{/*
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              

                <div className="details">
                  <h6>
                    Most Venerable Mahanayaka of Asgiriya Chapter of Siyam Maha
                    nikaya Warakagoda Sri Dhammasiddi Ganarathna Bidhana Thero
                    Asgiriya Maha Viharaya, Kandy
                  </h6>
                  <p>Venerable Patron</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              

                <div className="details">
                  <h6>
                    Most Venerable Mahanayaka of Malwathu Maha Vihara Chapter of
                    Siyam Maha Nikaya Tibbatuwawe Sri Siddartha Sumangalabidhana
                    Thero Malwathu Maha Viharaya, Kandy
                  </h6>
                  <p>Venerable Patron</p>
                </div>
              </div>
            </div>
          </div>

          */}

          {/*
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/1.png' alt='img' />
                  <ul className='team-social-inner'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='details'>
                  <h5>
                  <Link to='/team-details'>Devon Lane</Link>
                  </h5>
                  <p>Merketing Department</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                  <ul className='team-social-inner'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='details'>
                  <h5>
                  <Link to='/team-details'>Darrell Steward</Link>
                  </h5>
                  <p>It Department</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-team-inner text-center'>
                <div className='thumb'>
                  <img src='assets/img/team/3.png' alt='img' />
                  <ul className='team-social-inner'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='details'>
                  <h5>
                  <Link to='/team-details'>Bessie Cooper</Link>
                  </h5>
                  <p>Software Department</p>
                </div>
              </div>
            </div>
          </div>
*/}
        </div>

        {/* Executive Committee*/}
        <div className="container pt-5 ">
          <div className="section-title text-center">
            <h3 className="title">
              Official <span>Patrons</span>
            </h3>
          </div>

          <div className="row d-flex justify-content-center">

            

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Dr. Tilak Abeysekara <br/> Consultant Physician</h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Dr. Nishantha Nanayakkara <br/> Consultant Nephrologists</h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Dr. Saman Rajaguru <br/> Consultant Oncologist</h6>
                  <p>Official Patron</p>
                </div>
              </div>
            </div>

          </div>
        </div>


        {/* Official Patrons*/}
        <div className="container pt-5 ">
          <div className="section-title text-center">
            <h3 className="title">
              Executive <span>Committee</span>
            </h3>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>
                    Dr. T. Kannangara
                  </h6>
                  <p>President</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mrs. Lalanka Habaragamuwa</h6>
                  <p>Vice President</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mrs. Anoja Roopasinghe</h6>
                  <p>Vice President</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. K.A. Nawarathne</h6>
                  <p>Vice President</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mr. H.M.P. Kumarasiri</h6>
                  <p>Secretary</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mrs. Padma Lekamge</h6>
                  <p>Vice Secretary</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-team-inner text-center">
                {/*
                <div className='thumb'>
                  <img src='assets/img/team/2.png' alt='img' />
                 
                </div>
              */}

                <div className="details">
                  <h6>Mrs. Damayanthi Sumithrarachchi</h6>
                  <p>Treasurer</p>
                </div>
              </div>
            </div>

            

            

            

            <div className="btnlink d-flex justify-content-center">
            <a
              className="btn btn-black mt-0  border-radius-5"
              href="https://drive.google.com/file/d/1KIFBEjhy3l03fseG3yd-hWabkeQnEuSl/view"
              target="_blink"
            >
              Full Committee Members List
            </a>
          </div>



          </div>
        </div>


      </div>
      {/* ====================== team area end ======================*/}
    </>
  );
};

export default TeamAreaOne;
