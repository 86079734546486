import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

const ServiceAreaFour = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/*=================== service area start ===================*/}
      <div
        className="service-area bg-cover pd-top-120 pd-bottom-90 pd-top-110 pd-bottom-90"
        style={{ backgroundImage: 'url("./assets/img/bg/3.png")' }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title text-center">
                <h6 className="sub-title">OUR SERVICES</h6>
                <h5 className="title">
                  Get to know more about our past and{" "}
                  <span> current services </span>
                  or future plans for the welfare of patients.
                </h5>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4 col-md-4 align-items-center ">
              <div className="single-work-process-inner align-items-center">
                <div className="thumb mb-4 mx-auto text-center">
                  <img src="assets/img/icon/s1.svg" alt="img" />
                </div>
                <div className="details">
                  <h5 className="content mb-3 text-center">
                  Symptom Management
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-4 align-items-center ">
              <div className="single-work-process-inner align-items-center">
                <div className="thumb mb-4 mx-auto text-center">
                  <img src="assets/img/icon/s2.svg" alt="img" />
                </div>
                <div className="details">
                  <h5 className="content mb-3 text-center">
                  Emotional and Psychological Support
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-4 align-items-center ">
              <div className="single-work-process-inner align-items-center">
                <div className="thumb mb-4 mx-auto text-center">
                  <img src="assets/img/icon/s3.svg" alt="img" />
                </div>
                <div className="details">
                  <h5 className="content mb-3 text-center">
                  Spiritual Support
                  </h5>
                </div>
              </div>
            </div>

            {/*
            <div className="col-xl-4 col-md-4 align-items-center ">
              <div className="single-work-process-inner align-items-center">
                <div className="thumb mb-4 mx-auto text-center">
                  <img src="assets/img/icon/s4.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="content mb-3 text-center">
                    Helping both patient and the family.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-4 align-items-center ">
              <div className="single-work-process-inner align-items-center">
                <div className="thumb mb-4 mx-auto text-center">
                  <img src="assets/img/icon/s5.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="content mb-3 text-center">
                    Medical camps and seminars to protect public from Kidney
                    Problems.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-md-4 align-items-center ">
              <div className="single-work-process-inner align-items-center">
                <div className="thumb mb-4 mx-auto text-center">
                  <img src="assets/img/icon/s6.svg" alt="img" />
                </div>
                <div className="details">
                  <p className="content mb-3 text-center">
                    Psychological counseling of patients and family members.
                  </p>
                </div>
              </div>
            </div>
*/}

            <div class="d-flex justify-content-evenly">
              <Link
                className="btn btn-border-base "
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-duration="1500"
                to="/service"
                onClick={scrollToTop}
              >
                Discover More Services <FaPlus />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaFour;
