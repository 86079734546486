import React from "react";
import { FaPlus } from "react-icons/fa";

const ContactAreaFour = () => {
  return (
    <>
      {/*================= counter area start {/*=================*/}
      <div className="contact-area pd-top-90 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 order-lg-end "
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner ms-xl-5 p-xl-5 pt-4 ">
                <img
                  className="animate-main-img"
                  src="assets/img/about/18.png"
                  alt="img"
                />
                <img
                  className="main-img m-4"
                  src="assets/img/about/19 n.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 order-lg-first "
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 mt-5 mt-lg-0">
                <h6 className="sub-title">GET IN TOUCH</h6>
                <h2 className="title">
                Contact <span>Form</span>
                </h2>
                <p className="content">
                Questions? Comments? Ideas ? We’d love to hear them ! Please select the type of your enquiry, fill out this form and we will get back to you shortly. We make a living by what we get, but we make a life by what we give.
                </p>
                <form className="mt-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="single-input-inner style-border">
                        <input type="text" placeholder="Name Here" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-input-inner style-border">
                        <input type="text" placeholder="Contact Here" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-input-inner style-border">
                        <input type="text" placeholder="Email Here" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="single-input-inner style-border">
                        <textarea placeholder="Message" defaultValue={""} />
                      </div>
                    </div>
                    <div className="col-12">
                      <a className="btn btn-border-gray mt-0" href="#">
                        Contact Us <FaPlus />
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      

      {/*{/*================= counter area end {/*=================*/}

      {/* Map  */}
      <div className="contact-g-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15830.35242806453!2d80.6322636!3d7.2876155!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae36900436bb769%3A0x70fffa2d813cbcf5!2sPalliative%20care%20unit!5e0!3m2!1sen!2slk!4v1730226864525!5m2!1sen!2slk" />
      </div>

      
    </>
  );
};

export default ContactAreaFour;
