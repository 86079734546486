import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import BlogAreaTwo from "../components/BlogAreaTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import CounterAreaTwo from "../components/CounterAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarThree from "../components/NavbarThree";
import PricingAreaTwo from "../components/PricingAreaTwo";
import ServiceAreaTwo from "../components/ServiceAreaTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";
import FaqAreaTwo from "../components/FaqAreaTwo";
import ServiceAreaFour from "../components/ServiceAreaFour";

const HomeTwo = () => {
  return (
    <>
      {/* Navigation Bar Two*/}
      <NavbarThree />

      {/* Banner Two */}
      <BannerTwo />

      {/* About Area Two */}
      <AboutAreaTwo />

      {/* Work Process Two 
      <WorkProcessTwo />
      */}

      {/* About Area Two 
      <ServiceAreaTwo />

       Pricing Area Two 
      <PricingAreaTwo />

      */}

<ServiceAreaFour />
      
      <CounterAreaTwo />
      

      {/* Contact Area Two 
      <ContactAreaTwo />
      */}

      {/* FAQ Area Two */}
      <FaqAreaTwo />

      

      {/* Testimonial One 
      <TestimonialOne />
      */}

      {/* Blog Area Two 
      <BlogAreaTwo />
      */}

      {/* Footer Two */}
      <FooterTwo />

    </>
  );
};

export default HomeTwo;
