import React from "react";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className="about-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mb-4 mb-lg-0 "
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner">
                <img
                  className="main-img"
                  src="assets/img/about/1.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                Short Story About Palliative Care Unit.
                </h2>
                <p className="content mb-4">
                The Palliative treatment Unit at National Hospital Kandy is dedicated to providing compassionate treatment to patients with life-limiting illnesses, including cancer and non-cancer conditions.
<br/>
Our interdisciplinary team is made up of devoted consultants and doctors, nursing officers who specialise in palliative care nursing, pain management, and holistic support, counsellors who offer emotional support, and social service officers who help with practical needs. In addition, our physiotherapists increase mobility and comfort, while dedicated volunteers offer companionship and support. Together, we ensure that our patients and family receive comprehensive care that addresses their physical, emotional, and social needs. For more information, please contact us.
                </p>

                {/*
                <div className="row">
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Mistakes To Avoid to the
                      </li>
                      <li>
                        <FaCheckCircle /> Your Startup industry stan
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="single-list-inner style-check style-heading style-check mb-3">
                      <li>
                        <FaCheckCircle /> Mistakes To Avoid to the
                      </li>
                      <li>
                        <FaCheckCircle /> Your Startup industry stan
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="content">
                  SaaS providers typically invest heavily in security measures
                  to protect customer data, such as encryption and multi-factor
                  authentication. However, it is important to research and
                  choose a reputable provider and also to ensure that
                </p>

                */}
                
                <Link className="btn btn-border-base" to="/about">
                  Discover More <FaPlus />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
