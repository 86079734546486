import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import donationList from "../scripts/donationList";

const TeamAreaGroup = () => {
  return (
    <>
      <div className="service-area bg-relative pd-top-100">
        <img
          className="position-bottom-left top_image_bounce"
          src="assets/img/icon/4.png"
          alt="img"
        />
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">MAKE A DONATION</h6>
            <h3 className="title">
              Help Us to <span>Help Them !</span>
            </h3>
            
            <p className="content mb-0">
            Support for a palliative care unit is essential for several reasons, as these units play a critical role in improving the quality of life for patients facing life-limiting illnesses. 
            </p>
          </div>

          <div className="row">

            {donationList.map((data, index) => (
              <div className="col-lg-4 p-5 " key={index}>
                <div className="single-service-inner text-center">

                  <div className="thumb">
                    <img src={data.img} alt="img" />
                  </div>
                  <div className="details">
                    <h5>
                      {data.title}
                      {/*
                      <Link to='/service-details'>{data.title}</Link>
                      */}
                    </h5>

                    <p>
                      Account Number : {data.acc}
                      <br />
                      Account Number : {data.ac}
                      <br />
                      Bank Code : {data.bank}
                      <br />
                      Branch : {data.branch1}
                      <br />
                      Branch Code : {data.branch}
                      <br />
                      Swift Code : {data.swift}
                    </p>

                    {/*
                    <p>{data.des}</p>


                    <Link className='btn btn-border-base' to='/service-details'>
                      Touch More <FaPlus />
                    </Link>
*/}
                  </div>
                </div>
              </div>
            ))}

<div
              className="col-lg-8 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">

              <h5 className="title">
              Here’s <span>why</span> such <span>support</span>is necessary:
                </h5>

                <div
                    className="accordion accordion-inner style-3 accordion-icon-left mt-3"
                    id="accordionExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Patient-Centered Care
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                        Palliative care focuses on relieving the physical, emotional, and spiritual suffering of patients with serious illnesses. Providing adequate support ensures that patients receive comprehensive symptom management, pain relief, and personalized care, helping them live with dignity.

                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Family and Caregiver Support
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                        Families and caregivers also face emotional, financial, and practical challenges when caring for someone with a terminal illness. A well-supported palliative care unit offers counseling, respite care, and guidance, easing the burden on loved ones and helping them cope with grief.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Holistic Care
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                        Palliative care addresses not only physical symptoms but also psychological, social, and spiritual needs. Support for the unit allows for a multidisciplinary team (doctors, nurses, counselors, social workers) to collaborate, ensuring holistic care for the patient.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Improving Access
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                        Many regions, including areas like Kandy, may have limited access to specialized palliative care services. Support, whether financial, volunteer-based, or through advocacy, helps expand access to these essential services for underserved communities.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          Educational Development 
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                        Palliative care is still evolving, especially in developing regions. Supporting a unit can enable training and education for healthcare providers, improving their ability to manage end-of-life care effectively and compassionately.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapsesix"
                        >
                          Emotional and Spiritual Well-Being
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                        A palliative care unit helps patients find peace and meaning at the end of life. Support ensures that patients and families have access to psychological counseling, spiritual care, and other resources that can offer comfort during difficult times.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          Sustainability of Services 
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                        Palliative care units often rely on donations, government funding, or community support. Ongoing support helps ensure that these units can continue to offer their services to future patients and families without disruption.
                        </div>
                      </div>
                    </div>

                    <p className="content mb-4 mb-xl-5">
                    In essence, supporting a palliative care unit enhances the quality of life for patients, provides relief to families, and ensures that compassionate care is available when it is most needed.
                    </p>
                <br/>

                  </div>
                  
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default TeamAreaGroup;
